<template>
  <div id="reigsitInfo" class="div_reigsit">
    <p class="myclass-title">
      <span class="title-text">注册信息</span>
      <span class="title-border"></span>
    </p>

    <div v-for="(item,index) in pageListData" :key="index" class="div_page_content">
      <!-- 绑定头像    -->
      <div v-if="item.type==5" class="style_flex div_item">
        <div class="div_left style_flex">
          <span v-show="item.required===1" class="p_xh">*</span>
          <span>{{ item.fields_remark }}:</span>
        </div>
        <div class="div_right">
          <div class="div_icon">
            <my-upload
              v-if="show_box"
              ref="myUpload"
              :model-value.sync="show_box"
              field="img"
              :no-square="true"
              img-format="jpg"
              :size="size"
              url="https://f.zhulong.com/uploadZhulongImgForEdit.php?type=avatar"
              @crop-upload-success="(res, file)=>handleAvatarSuccess(res, file,item)"
            ></my-upload>
            <div class="head" @click="toggleShow">
              <img class="iv_avatar" :src="item.value" onerror="javascript:this.src='https://newoss.zhulong.com/tfs/noavatar_big.gif'">
              <div class="mask div_edi_user_icon" style="cursor:pointer;">修改头像</div>
            </div>
          </div>
        </div>
      </div>
      <!-- 通用文本    -->
      <div v-else-if="item.type==1">
        <div v-if="item.fields_name == 'user_tags' || item.fields_name == 'certificate'" class="style_flex div_item" style="align-items: flex-start">
          <div class="div_left style_flex">
            <span v-show="item.required===1" class="p_xh">*</span>
            <span>{{ item.fields_remark }}：</span>
          </div>
          <div class="div_right style_flex" style="  flex-direction: column">
            <input
              v-for="(itemS,index) in item.value2"
              :key="index"
              v-model="item.value2[index]"
              :disabled="item.type==1&&item.disabled==1"
              class="input"
              type="text"
              placeholder="非必填"
              :style="index>0?'margin-top:12px':'display:block'"
            >
            <div class="addNew" :style="item.value2.length>0?'margin-top:12px':''">
              <span style="cursor: pointer;" @click="item.value2.push('')">
                <i class="el-icon-plus"></i>{{ item.fields_remark.includes("标签") ? '添加标签':'添加证书' }}</span>
            </div>
          </div>
        </div>
        <div v-else class="style_flex div_item" style="align-items: flex-start">
          <div class="div_left style_flex">
            <span v-show="item.required===1" class="p_xh">*</span>
            <span>{{ item.fields_remark }}：</span>
          </div>
          <div class="div_right style_flex">
            <span v-if="item.fields_remark=='用户名' && is_work_account==1" style="text-align:left;">{{ item.value }}</span>
            <input v-else v-model="item.value" class="input" :disabled="item.type==1&&item.disabled==1" style="float: left;" type="text" :placeholder="'请填写您的'+item.fields_remark">
            <span v-show="item.fields_remark=='用户名'" class="explain">（用于个人主页）</span>
          </div>
        </div>

      </div>
      <!-- 绑定手机号    -->
      <div v-else-if="item.type==7" class="style_flex div_item">
        <div class="div_left style_flex">
          <span v-show="item.required===1" class="p_xh">*</span>
          <span>{{ item.fields_remark }}：</span>
        </div>
        <div class="div_right style_flex">
          <span class="span_tel" style="color: #ee2e2e;text-align: left">{{ item.value || '正在加载' }}</span>
          <button class="btn_update_tel" @click="isShowBindPhoneDialog(1)">{{ bindPhoneBtnName }}</button>
        </div>
      </div>
      <!-- 绑定微信    -->
      <div v-else-if="item.type==8" class="style_flex div_item">
        <div class="div_left style_flex">
          <span v-show="item.required===1" class="p_xh">*</span>
          <span>{{ item.fields_remark }}：</span>
        </div>
        <div class="div_right style_flex">
          <span class="span_tel" style="color: #ee2e2e;text-align: left">{{ item.value1.is_bind_weixin == 0 ? '未绑定' : item.value1.bind_wx || '微信昵称' }}</span>
          <button class="btn_update_tel" @click="isShowBindNicknameDialog(1)">{{ item.value1.is_bind_weixin ? '申请换绑' : '绑定' }}</button>
        </div>
      </div>
      <!-- 性别    -->
      <div v-else-if="item.type==2" class="style_flex div_item">
        <div class="div_left style_flex">
          <span v-show="item.required===1" class="p_xh">*</span>
          <span>{{ item.fields_remark }}：</span>
        </div>
        <div class="div_right style_flex">
          <div class="radio" :class="{checked:item.value===0,noChecked:item.value!==0}" @click="item.value = 0">男</div>
          <div class="radio" :class="{checked:item.value===1 ,noChecked:item.value!==1}" @click="item.value = 1">女</div>
        </div>
      </div>
      <!-- 生日    -->
      <div v-else-if="item.type==3" class="style_flex div_item">
        <div class="div_left style_flex">
          <span v-show="item.required===1" class="p_xh">*</span>
          <span>{{ item.fields_remark }}：</span>
        </div>
        <div class="div_right style_flex">
          <el-date-picker
            v-model="item.value"
            class="select_calss1"
            type="date"
            placeholder="选择日期"
            value-format="yyyy-MM-dd"
          >
          </el-date-picker>
        </div>
      </div>
      <!-- 兴趣点|专业    -->
      <div v-else-if="item.type==10" class="style_flex div_item">
        <div class="div_left style_flex">
          <span v-show="item.required===1" class="p_xh">*</span>
          <span>{{ item.fields_remark }}：</span>
        </div>
        <div class="div_right style_flex">
          <el-popover
            v-model="visible"
            placement="bottom"
            title="选择兴趣点"
            width="420"
            trigger="click"
          >
            <div class="chooseDiv" style="margin: 0px;max-height:360px;overflow: auto;overflow-x: hidden;">
              <ul>
                <li v-for="(item,index1) in resultMajor.result.list" :key="item.id" class="clearfix chooseMajorLi">
                  <p align="left">{{ item.category_name }}</p>
                  <dl v-for="item in resultMajor.result.list[index1].childList" :key="item.id" class="chooseDivLi chooseMajorDl">
                    <a class="chooseDivA" @click="showMajorDialog(0,item.id,item.category_name),visible=false">
                      {{ item.category_name }}
                    </a>
                  </dl>
                </li>
              </ul>
            </div>
            <input slot="reference" v-model="item.value1.specialty" class="input" readonly="readonly" type="text" :placeholder="'请选择'+item.fields_remark" @click="isShowCityDialog(1,item)">
          </el-popover>
        </div>
      </div>
      <!-- 地区    -->
      <div v-else-if="item.type==6" class="style_flex div_item">
        <div class="div_left style_flex">
          <span>{{ item.fields_remark }}：</span>
        </div>
        <div class="div_right style_flex">
          <el-popover
            v-model="visible_address"
            placement="bottom"
            title="选择地区"
            width="420"
            trigger="click"
          >
            <p class="dialogCommonP chooseP" style="margin-bottom: 0">
              <span>所在地区</span>&nbsp;
              <a v-if="cityLevel>1" class="chooseA" href="javascript:void (0)" @click="backProvince()">[返回上一级]</a>
            </p>
            <div class="chooseDiv" style="min-height: 180px">
              <ul>
                <li v-for="item in provinceData" v-if="cityLevel===1" :key="item.id" class="chooseCityLi chooseDivLi">
                  <a class="chooseDivA" @click="showCity(item.id,item.name)">{{ item.name }}</a>
                </li>

                <li v-for="item1 in cityData" v-if="cityLevel===2" class="chooseCityLi chooseDivLi">
                  <a class="chooseDivA" @click="showCity(item1.id,item1.name)">{{ item1.name }}</a>
                </li>

                <li v-for="item2 in areaData" v-if="cityLevel===3" class="chooseCityLi chooseDivLi">
                  <a class="chooseDivA" @click="showCity(item2.id,item2.name),visible_address=false">{{ item2.name }}</a>
                </li>
              </ul>
            </div>
            <input slot="reference" v-model="provinceAndCityName" class="input" readonly="readonly" type="text" :placeholder="'请选择'+item.fields_remark" @click="isShowCityDialog(1,item)">
          </el-popover>
        </div>
      </div>
      <!-- 学历    -->
      <div v-else-if="item.type==11" class="style_flex div_item">
        <div class="div_left style_flex">
          <span v-show="item.required===1" class="p_xh">*</span>
          <span>{{ item.fields_remark }}：</span>
        </div>
        <div class="div_right style_flex">
          <el-select v-model="item.value" class="el_select" clearable :placeholder="'请选择您的'+item.fields_remark" @change="(val)=>currentSel(val,item)">
            <el-option v-for="itemS in options" :key="itemS.value" :label="itemS.label" :value="itemS.value" class="item" :popper-append-to-body="false">
            </el-option>
          </el-select>
        </div>
      </div>
      <!-- 工作年限    -->
      <div v-else-if="item.type==13" class="style_flex div_item">
        <div class="div_left style_flex">
          <span v-show="item.required===1" class="p_xh">*</span>
          <span>{{ item.fields_remark }}：</span>
        </div>
        <div class="div_right style_flex">
          <el-select v-model="item.value" class="el_select" clearable :placeholder="'请选择您的'+item.fields_remark" @change="(val)=>currentSel(val,item)">
            <el-option v-for="itemS in workYear" :key="itemS.value" :label="itemS.label" :value="itemS.value" class="item" :popper-append-to-body="false">
            </el-option>
          </el-select>
        </div>
      </div>
      <!-- 毕业时间   -->
      <div v-else-if="item.type==12" class="style_flex div_item">
        <div class="div_left style_flex">
          <span v-show="item.required===1" class="p_xh">*</span>
          <span>{{ item.fields_remark }}：</span>
        </div>
        <div class="div_right style_flex">
          <el-date-picker
            v-model="item.value"
            class="select_calss1"
            type="year"
            placeholder="选择日期"
            value-format="yyyy"
          >
          </el-date-picker>
        </div>
      </div>
      <!-- 文本域     -->
      <div v-else-if="item.type==9" class="style_flex div_item">
        <div class="div_left style_flex">
          <span v-show="item.required===1" class="p_xh">*</span>
          <span>{{ item.fields_remark }}：</span>
        </div>
        <div class="div_right style_flex">
          <el-input
            v-model="item.value"
            type="textarea"
            class="textarea"
            :autosize="{ minRows: 3, maxRows: 8}"
            rows="4"
            :placeholder="'请填写您的'+item.fields_remark"
          >
          </el-input>
        </div>
      </div>
    </div>
    <button v-show="pageListData.length>0" class="btn_save_info" @click="saveRigstInfo()">保存</button>

    <!--手机绑定-->
    <div v-show="isShowBindPhonePop" class="dialogCommon dialogBindPhone">
      <p class="dialogCommonP">
        <span class="dialogBindPhoneTitle">手机绑定</span>&nbsp;
        <img class="dialogCommonImg" style="width: 20px;height:20px;margin-top: 10px" src="../../../assets/image/white_close.png" alt="close" @click="isShowBindPhoneDialog(0)">
      </p>
      <div style="margin: 0">
        <ul>
          <li v-show="showBindPhoneTitle">
            <span>*当前绑定手机号：</span>
            <span v-cloak>{{ bindPhone }}</span>
          </li>
          <li>
            <span>*手机号码：</span>
            <label>
              <input v-model="paramsBindPhone.mobile" class="input" type="text" maxlength="11" oninput="value=value.replace(/[^\d]/g,'')" placeholder="请输入手机号">
            </label>
          </li>
          <li>
            <span style="display: inline-block; position: relative; top: -10px ">*验证码：</span>
            <div class="dialogBindCheckedCode">
              <label>
                <input v-model="paramsBindPhone.code" type="text" maxlength="8" placeholder="请输入验证码">
              </label>
              <span class="line" />
              <input v-model="btnMsg == null ? countNum+'s 重新发送' : btnMsg" type="button" :disabled="countFlag" class="getCode" @click="getVerificationCode()">
            </div>
          </li>
        </ul>
        <button class="btn_bind_Phone" @click="submitBindPhone()">立即绑定</button>

      </div>
    </div>
    <!-- 微信绑定 -->
    <div v-show="isShowBindNicknamePop" class="dialogCommon dialogBindPhone dialogBindPhone_wx">
      <p class="dialogCommonP" style="text-align: center;">
        <span class="dialogBindPhoneTitle" style="font-size: 16px">更换微信账号绑定</span>&nbsp;
        <img class="dialogCommonImg" style="width: 20px;height:20px;margin-top: 10px" src="../../../assets/image/white_close.png" alt="close" @click="isShowBindNicknameDialog(0)">
      </p>
      <div style="margin: 10px 0 0">
        <p>
          请用新微信号扫一扫关注公众号
        </p>
        <p style="margin: 0 0 13px">
          重新关联
        </p>
        <p style="color: #ee2e2e;">
          每个账号一个月仅可换绑1次
        </p>
        <p v-if="bindInfoObj.change_bind_able == 0" style="color: #ee2e2e;margin: 13px 0 0;">
          本月已到达次数上限
        </p>
      </div>
      <div v-if="bindInfoObj.change_bind_able != 0" style="display: flex;justify-content: end;">
        <button class="btn_bind_Phone" style="marginTop: 23px;" @click="toLoginBing()">换绑微信</button>
        <button style="margin-left: 15px;margin-left: 15px;background: #fff;border: 1px solid #bbb;color: #333;" class="btn_bind_Phone" @click="isShowBindNicknameDialog(0)">取消</button>
      </div>
    </div>
    <div v-show="isShowYinYing" class="view_yiying" />
  </div>
</template>
<script>
import Cookies from 'js-cookie'
// ESlint报错，原代码提交记录 caolinxing
import {
  bindPhone,
  getEduConfig,
  getMajorList,
  getProvinceCityList,
  getRegistInfo,
  newRegisInfo,
  getNewRegisInfo,
  requestBindEmail,
  sendEmailCode,
  sendPhoneCode,
  updateIcon,
  updateRegistInfo
} from '../../../api/mine'
import { sendMobileCode, bindMobile, getLoginUserInfo, removeBindData } from '@/api/login'
import { mapActions, mapMutations } from 'vuex'
import 'babel-polyfill'
import myUpload from 'vue-image-crop-upload'

export default {
  components: {
    'my-upload': myUpload
  },
  data() {
    return {
      visible: false,
      visible_address: false,
      handleItem: '',
      // 1文本 4附件 5图片 6地区 7绑定手机号 8绑定微信
      // 10 兴趣点/专业 11最高学历 12毕业时间   13 工作年限 14职称等级'
      pageListData: [],
      /* {
              type: 5,
              fields_name: "training",
              fields_remark: "修改头像",
              value:''
            },{
              type: 1,
              fields_name: "training",
              fields_remark: "用户名",
              value:''
            },{
              type: 1,
              fields_name: "training",
              fields_remark: "真实姓名",
              value:''
            },{
              type: 7,
              fields_name: "training",
              fields_remark: "绑定手机号",
              value:''
            },{
              type: 8,
              fields_name: "training",
              fields_remark: "绑定微信",
              value:''
            },{
              type: 2,
              fields_name: "training",
              fields_remark: "性别",
              value:0
            },{
              type: 3,
              fields_name: "training",
              fields_remark: "生日",
              value:''
            },{
              type: 10,
              fields_name: "training",
              fields_remark: "兴趣点",
              value: {id:'',name:''}
            },{
              type: 6,
              fields_name: "training",
              fields_remark: "地区",
              value:{province_id:'',province_name:'',city_id:'',city_name:'',county_id:'',county_name:''}
            },{
              type: 1,
              fields_name: "training",
              fields_remark: "学校",
              value:''
            },{
              type: 1,
              fields_name: "training",
              fields_remark: "所学专业",
              value:''
            },{
              type: 11,
              fields_name: "training",
              fields_remark: "学历",
              value:''
            },{
              type: 12,
              fields_name: "training",
              fields_remark: "毕业时间",
              value:''
            },{
              type: 1,
              fields_name: "training",
              fields_remark: "公司名称",
              value:''
            },{
              type: 4,
              fields_name: "training",
              fields_remark: "资格证书",
              value:['1','2']
            },{
              type: 4,
              fields_name: "training",
              fields_remark: "添加标签",
              value:['3','4']
            },{
              type: 9,
              fields_name: "training",
              fields_remark: "个人简介",
              value:''
            },{
              type: 1,
              fields_name: "training",
              fields_remark: "邮箱",
              value:''
            }*/
      // 240613 改版字段分割线

      // 倒计时周期
      countNum: 60,
      intervalBtn: null,
      // 用于倒计时标记，true-正在倒计时
      countFlag: false,
      // 默认按钮的值
      btnMsg: '获取验证码',
      showBindPhoneTitle: false,
      showEamilTip: false,
      bindPhoneBtnName: '绑定手机号',
      bindNicknameBtnName: '绑定',
      // 禁止多次点击
      canClick: true,
      cityLevel: 0,
      years: [],
      year: '',
      month: '',
      day: '',
      yearG: '',
      monthG: '',
      dayG: '',
      provinceAndCityName: '',
      provinceName: '',
      cityName: '',
      countyName: '',
      resultEduConfig: {
        errNo: 0,
        msg: '',
        result: [{ id: '', education: '' }]
      },
      options: [],
      workYear: [
        { value: '0', label: '一年或一年以内' },
        { value: '1', label: '二年' },
        { value: '2', label: '四年' },
        { value: '3', label: '三年' },
        { value: '4', label: '五年' }
      ],
      formNewTask: this.$route.query.formNewTask || 0,
      value4: '',
      bindPhone: '',
      // 显示城市弹窗
      isShowCityPop: false,
      // 绑定手机号弹窗
      isShowBindPhonePop: false,
      // 绑定微信弹窗
      isShowBindNicknamePop: false,
      // 显示省列表
      isProvince: true,
      cityPosition: 0,
      // 选择男/女
      isChooseMan: true,
      // 显示专业弹窗
      isShowMajor: false,
      // 阴影
      isShowYinYing: false,
      // 生日：
      birthday: '',
      // 专业名称
      majorName: '',
      // 修改头像
      paramsUpdateIcon: {
        filename: ''
      },
      resultMajor: {
        errNo: 0,
        msg: '',
        result: {
          count: 287,
          list: [{
            id: '',
            parent_id: '',
            category_name: '',
            level: 1,
            path: '',
            description: '',
            childList: [],
            children: {
              '2': {
                id: '',
                parent_id: '',
                category_name: '',
                level: '',
                path: '',
                description: '',
                children: null
              }
            }
          }]
        }
      },

      provinceData: [{
        id: '',
        parent_id: '',
        name: ''
      }],
      cityData: [{
        id: '',
        parent_id: '',
        name: ''
      }],
      areaData: [{
        id: '',
        parent_id: '',
        name: '',
        level: ''
      }],
      // 绑定手机号入参
      paramsBindPhone: {
        mobile: '',
        code: ''
      },
      // 绑定手机号入参
      paramSendPhonePhone: {
        mobile: ''
      },
      // 获取邮箱验证码
      sendEmail: {
        email: ''
      },
      // 获取邮箱验证码
      bindEmail: {
        email: '',
        code: ''
      },
      // 保存注册信息入参
      paramRegister: {
        // 用户名
        username: '',
        // 真实姓名
        realname: '',
        // 性别
        gender: '',
        // 学校名称
        school_name: '',
        // 简介
        intro: '',
        // 生日
        birthday: '',
        // 专业id
        specialty_id: '',
        // 学历Id
        degree: '',
        // 省份id
        province_id: '',
        // 城市id
        city_id: '',
        // 区id
        county_id: '',
        // 邮箱
        email: '',
        // 所学专业
        major: ''

      },
      majorInfo: [],

      params_citys: {
        parent_id: ''
      },
      resultData: {
        errNo: 0,
        result: {
          avatar: require('../../../assets/image/avatar.png'),
          username: '',
          realname: '',
          mobile: '',
          gender: '',
          birthday: '',
          specialty_id: '',
          province_id: '',
          province: '',
          city_id: '',
          city: '',
          school_name: '',
          specialty: '',
          degree: '',
          email: '',
          intro: '',
          company: '',
          certificate: [''],
          graduation_time: '',
          user_tags: ['']

        }
      },
      bindInfoObj: {
        wx_nickname: '',
        is_bind_weixin: '',
        change_bind_able: ''
      },
      show_box: false, // 剪切框显示和隐藏的flag
      size: 2.1,

      is_work_account: ''
    }
  },

  computed: {
    // 控制显示的内容
    computedTxt() {
      return function(str) {
        return str.split('-')
      }
    }

  },

  created() {
    // 获取【年】数据
    const date = new Date()
    for (let i = 1900; i <= date.getFullYear(); i++) {
      this.years.unshift(i)
    }
    // 获取学历信息
    getEduConfig().then((res) => {
      this.resultEduConfig.errNo = res.errNo
      this.resultEduConfig.msg = res.msg
      this.resultEduConfig.result = res.result
      const result = this.resultEduConfig.result
      for (let i = 0; i < result.length; i++) {
        var obj = {
          value: '',
          label: ''
        }
        obj.value = JSON.stringify(result[i].id)
        obj.label = result[i].education
        this.options.push(obj)
      }
    })
    // 获取省市列表
    this.getProvinceCity()
    // 获取注册信息
    this.getRegistInfo()
    // 获取专业列表
    this.getMajorInfo()
    // 获取微信号
    this.getLoginUserInfo()
  },
  methods: {
    ...mapMutations(['storageToken']),
    ...mapActions({
      'cacheUsername': 'userInfo/storageTheUsername',
      'cacheUid': 'userInfo/storageTheUid',
      'cacheAvatar': 'userInfo/storageTheAvatar',
      'cacheVipLevel': 'userInfo/storageTheVipLevel'
    }),
    // 上传组件头像
    toggleShow() {
      this.show_box = !this.show_box
      // this.$refs.myUpload.setStep(1)
    },
    // 倒计时
    countDown() {
      console.log('计时器启动：' + this.countNum)
      // 设置btn倒计时时显示的信息
      this.btnMsg = null
      // 更改btn状态
      this.countFlag = !this.countFlag
      // 设置倒计时
      this.intervalBtn = setInterval(() => {
        if (this.countNum <= 0) {
          // 重置btn提示信息
          this.btnMsg = '获取验证码'
          // 清除定时器
          clearInterval(this.intervalBtn)
          // 更改btn状态
          this.countFlag = !this.countFlag
          // 重置倒计时状态
          this.countNum = 60
        }

        // 倒计时
        this.countNum--
      }, 1000)
    },

    currentSel(selVal, item) {
      console.log(item)
      item.value = selVal
    },
    // 修改用户头像
    handleAvatarSuccess(res, file, item) {
      this.show_box = false
      this.paramsUpdateIcon.filename = res.url
      updateIcon(this.paramsUpdateIcon).then((res) => {
        if (res.errNo === 0) {
          this.$message.success('头像修改成功')
          var avatar = res.result.result || ''
          avatar = avatar.split('?')[0]
          var timestamp = parseInt(new Date().getTime() / 1000)
          avatar = avatar + '?t=' + timestamp
          item.value = avatar
          console.log('修改头像成功 ', avatar)
          this.cacheAvatar({ avatar: avatar })
          location.reload()
        }
      })
    },
    beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 2
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!')
      }
      return isLt2M
    },
    successTip(msg) {
      this.$message({
        message: msg,
        type: 'success'
      })
    },
    warningTip(msg) {
      this.$message({
        message: msg,
        type: 'warning'
      })
    },
    normalTip(msg) {
      this.$message(msg)
    },
    errorTip(msg) {
      this.$message({
        message: msg,
        type: 'error'
      })
    },
    // 获取手机验证码
    getVerificationCode() {
      if (this.paramsBindPhone.mobile) {
        const appid = '2090316930' // 腾讯云控制台中对应这个项目的 appid
        // 生成一个滑块验证码对象
        const that = this
        const captcha = new TencentCaptcha(appid, function(res) { // eslint-disable-line
          // 用户滑动结束或者关闭弹窗，腾讯返回的内容
          console.log(res, '------------res')
          if (res.ret == '0') {
            console.log(res, '------------res1')
            console.log('----------------------------------')
            that.paramSendPhonePhone.mobile = that.paramsBindPhone.mobile
            const info = {
              mobile: that.paramsBindPhone.mobile,
              code_type: 2,
              'randstr': res.randstr,
              'ticket': res.ticket
            }
            console.log(info, '-----------------------2-----------')

            sendMobileCode(info).then(res => {
              if (res.errNo === 0) {
                that.successTip('发送验证码成功')
                that.countDown()
              } else {
                that.errorTip(res.msg)
              }
            })
          } else {
            // 提示用户完成验证
          }
        })
        // 滑块显示
        captcha.show()
      }

      // sendPhoneCode(this.paramSendPhonePhone).then((res) => {
      //   if (res.errNo === 0) {
      //     this.successTip('发送验证码成功')
      //     this.countDown()
      //   } else {
      //     this.errorTip(res.msg)
      //   }
      // })
      // 触发定时器方法
    },
    /* 绑定手机*/
    submitBindPhone() {
      if (this.paramsBindPhone.mobile.length === 11 && this.paramsBindPhone.code.length >= 2) {
        const info = {
          uuid: Cookies.get('uuid'),
          mobile: this.paramsBindPhone.mobile,
          code: this.paramsBindPhone.code,
          access_token: Cookies.get('access_token')
        }
        bindMobile(info).then(res => {
          if (res.errNo === 0) {
            this.successTip('绑定手机号成功')
            this.resultData.result.mobile = this.paramsBindPhone.mobile
            this.isShowBindPhonePop = false
          } else {
            this.errorTip(res.msg)
          }
        })
        // bindPhone(this.paramsBindPhone).then((res) => {
        //   if (res.errNo === 0) {
        //     this.successTip('绑定手机号成功')
        //     this.resultData.result.mobile = this.paramsBindPhone.mobile
        //     this.isShowBindPhonePop = false
        //   } else {
        //     this.errorTip(res.msg)
        //   }
        // })
      } else {
        this.warningTip('请输入正确的验证码')
      }
    },
    sendEmailLink() {
      this.sendEmail.email = this.paramRegister.email
      sendEmailCode(this.sendEmail).then((res) => {
        if (res.errNo === 0) {
          this.showEamilTip = true
        } else {
          this.errorTip(res.msg)
        }
      })
    },

    bindEmailFun() {
      this.bindEmail.email = this.paramRegister.email
      if (this.bindEmail.code.length > 2) {
        requestBindEmail(this.bindEmail).then((res) => {
          if (res.errNo !== 0) {
            this.errorTip(res.msg)
          }
        })
      }
    },

    // 获取注册信息
    getRegistInfo() {
      // 获取学历信息
      getNewRegisInfo().then((res) => {
        this.pageListData = res.result
        this.pageListData.forEach(item => {
          if (item.type == 6) {
            this.provinceAndCityName = item.value1.province + ' ' + item.value1.city + ' ' + item.value1.county
          }
        })
        console.log(this.pageListData, '--------------------')
        /* this.paramRegister.province_id = info.province_id
        this.paramRegister.city_id = info.city_id
        this.paramRegister.county_id = info.county_id ? info.county_id : ''
        this.paramRegister.email = info.email
        this.majorName = info.specialty
        this.paramRegister.education = info.education
        this.provinceName = info.province ? info.province : ''
        this.cityName = info.city ? info.city : ''
        this.countyName = info.county ? info.county : ''
        this.provinceAndCityName = this.provinceAndCityName.trim()*/
      })
      /*
            getRegistInfo().then((res) => {
              this.resultData.errNo = res.errNo
              this.resultData.msg = res.msg
              this.resultData.result = res.result

              if (res.result.certificate === '') {
                this.resultData.result.certificate = ['']
              } else {
                this.resultData.result.certificate = res.result.certificate.split(',')
                this.resultData.result.certificate = this.resultData.result.certificate.filter(item => item)
              }
              if (res.result.user_tags === '') {
                this.resultData.result.user_tags = ['']
              } else {
                this.resultData.result.user_tags = res.result.user_tags
              }
              const info = this.resultData.result
              if (info.mobile.length > 0) {
                this.bindPhone = info.mobile
                this.bindPhoneBtnName = '申请换号'
                this.showBindPhoneTitle = true
              } else {
                this.showBindPhoneTitle = false
                this.bindPhoneBtnName = '绑定手机号'
              }
              console.log(res.result.username, 'res.result.username')
              var avatar = res.result.avatar || ''
              avatar = avatar.split('?')[0]
              var timestamp = parseInt(new Date().getTime() / 1000)
              avatar = avatar + '?t=' + timestamp
              this.resultData.result.avatar = avatar
              console.log(avatar, 'avatar')
              this.cacheUsername({ username: res.result.username })
              this.cacheAvatar({ avatar: avatar })
              Cookies.set('username', res.result.username, { expires: 7 })
              this.paramRegister.username = info.username
              this.paramRegister.realname = info.realname
              var getGender = '0'
              if (info.gender == '男' || info.gender == 0) {
                getGender = '0'
              }
              if (info.gender == '女' || info.gender == 1) {
                getGender = '1'
              }
              this.paramRegister.gender = getGender
              this.paramRegister.degree = info.degree
              this.paramRegister.intro = info.intro
              this.paramRegister.major = info.major

              // 0男1女
              if (this.paramRegister.gender == 0) {
                this.isChooseMan = true
              } else {
                this.isChooseMan = false
              }

              // 生日
              this.paramRegister.birthday = info.birthday

              this.paramRegister.school_name = info.school_name
              this.paramRegister.specialty_id = info.specialty_id
              for (const i in this.resultEduConfig.result) {
                if (this.paramRegister.degree == this.resultEduConfig.result[i].id) {
                  this.value4 = this.resultEduConfig.result[i].education
                }
              }
              this.paramRegister.province_id = info.province_id
              this.paramRegister.city_id = info.city_id
              this.paramRegister.county_id = info.county_id ? info.county_id : ''
              this.paramRegister.email = info.email
              this.majorName = info.specialty
              this.paramRegister.education = info.education
              this.provinceName = info.province ? info.province : ''
              this.cityName = info.city ? info.city : ''
              this.countyName = info.county ? info.county : ''
              this.provinceAndCityName = this.provinceName + ' ' + this.cityName + ' ' + this.countyName
              this.provinceAndCityName = this.provinceAndCityName.trim()
            })
      */
    },

    /* 地区列表*/
    getProvinceCity() {
      getProvinceCityList(this.params_citys).then((res) => {
        this.canClick = true
        if (parseInt(res.errNo) === 0) {
          if (this.cityLevel === 0) {
            this.provinceData = res.result
          } else if (this.cityLevel === 1) {
            this.isShowCityPop = true
            this.isShowYinYing = true
            this.cityData = res.result
          } else {
            this.isShowCityPop = true
            this.isShowYinYing = true
            this.areaData = res.result
          }
          this.cityLevel++
        }
      })
    },

    /* 获取专业*/
    getMajorInfo() {
      const params = {
        is_old: 1
      }
      getMajorList().then((res) => {
        if (res.errNo === 0) {
          this.resultMajor.msg = res.msg
          this.resultMajor.result = res.result
          const datas = this.resultMajor.result.list
          for (const i in datas) {
            const list = datas[i].children
            const childs = []
            for (const j in list) {
              const obj = {
                id: '',
                category_name: ''
              }
              obj.id = list[j].id
              obj.category_name = list[j].category_name
              childs.push(obj)
            }
            datas[i].childList = childs
          }
        }
      })
    },

    /* 保存注册信息*/
    saveRigstInfo() {
      const paramObj = {}
      for (let i = 0; i < this.pageListData.length; i++) {
        const item = this.pageListData[i]
        if (item.fields_remark != '头像' && item.type !== 8 && item.type !== 7) {
          if (item.required === 1) {
            if ((item.type == 10 || item.type == 6)) {
              if (!item.value1 || !item.value1.specialty_id) {
                console.log(item.value1, '-------------' + !item.value1 || !item.value1.specialty_id)
                return this.warningTip(item.fields_remark + '不能为空')
              }
            } else if (!item.value && item.value !== 0) {
              return this.warningTip(item.fields_remark + '不能为空')
            } else if (item.fields_remark == '真实姓名') {
              const FloatRegex = /[^\u4e00-\u9fa5]/
              if (FloatRegex.test(item.value)) {
                return this.errorTip('请输入真实姓名')
              }
            }
          }
          if (item.type == 1 && (item.fields_remark.includes('标签') || item.fields_remark.includes('资格证书'))) {
            // 标签/证书
            paramObj[item.fields_name] = item.value2.join(',')
          } else if (item.type == 6) {
            // 地区
            paramObj[item.fields_name] = JSON.stringify(item.value1)
          } else if (item.type == 10) {
            // 专业/兴趣点
            paramObj[item.fields_name] = item.value1[item.fields_name]
          } else {
            paramObj[item.fields_name] = item.value
          }
        }
      }
      console.log(paramObj)
      newRegisInfo(paramObj).then((res) => {
        if (res.errNo === 0) {
          this.successTip('修改注册信息成功')
          this.getRegistInfo()
          if (this.formNewTask) this.$router.push('taskcenter')
        } else {
          this.errorTip(res.msg)
        }
      })
      this.bindEmailFun()
      this.paramRegister.intro = this.resultData.result.intro
      this.paramRegister.company = this.resultData.result.company
      this.resultData.result.certificate = this.resultData.result.certificate.filter(item => item)
      this.paramRegister.certificate = this.resultData.result.certificate.join(',')
      this.paramRegister.user_tags = JSON.stringify(this.resultData.result.user_tags.filter((item) => { return item != '' }))
      this.paramRegister.graduation_time = this.resultData.result.graduation_time
      this.paramRegister.birthday = this.resultData.result.birthday
      /* updateRegistInfo(this.paramRegister).then((res) => {
         if (res.errNo === 0) {
           this.successTip('修改注册信息成功')
           this.getRegistInfo()
           if (this.formNewTask) this.$router.push('taskcenter')
         } else {
           this.errorTip(res.msg)
         }
       })*/
    },
    /* 绑定手机号弹窗*/
    isShowBindPhoneDialog(isShow) {
      if (isShow == '1') {
        this.isShowBindPhonePop = true
        this.isShowYinYing = true
      } else {
        this.isShowBindPhonePop = false
        this.isShowYinYing = false
      }
    },
    /* 绑定微信弹窗*/
    isShowBindNicknameDialog(isShow) {
      if (isShow == '1') {
        this.isShowBindNicknamePop = true
        this.isShowYinYing = true
      } else {
        this.isShowBindNicknamePop = false
        this.isShowYinYing = false
      }
    },
    /* 控制选择专业弹窗*/
    showMajorDialog(isShow, majorId, majorName, item) {
      if (isShow == 1) {
        // 显示
        this.isShowMajor = true
        // this.isShowYinYing = true;
      } else {
        // 不显示
        this.isShowMajor = false
        this.isShowYinYing = false
        if (majorId != null && majorName != null) {
          this.majorName = majorName
          console.log(this.handleItem, '---------')
          if (!this.handleItem.value1) {
            this.handleItem.value1 = {}
          }

          this.handleItem.value1.specialty = majorName

          this.paramRegister.specialty_id = majorId
          this.handleItem.value1.specialty_id = majorId
          this.$forceUpdate()
          console.log(isShow + '---' + majorId + '---' + majorName)
        }
      }
      if (item) {
        this.handleItem = item
        console.log(this.handleItem.toString())
      }
    },

    /* 控制城市弹窗*/
    isShowCityDialog(isShow, value) {
      this.cityLevel = 1
      if (value) {
        this.handleItem = value
        console.log(this.handleItem, '------------this.handleItem')
      }
    },
    /* 显示城市列表*/
    showCity(pid, cname) {
      console.log(this.handleItem, '------------this.handleItem')
      console.log(this.cityLevel)
      console.log(pid + '  ' + cname)
      if (this.cityLevel === 1) {
        // 省
        this.paramRegister.province_id = pid
        this.handleItem.value1.province_id = pid

        this.paramRegister.city_id = ''
        this.paramRegister.county_id = ''

        this.provinceName = cname
        this.cityName = ''
        this.countyName = ''

        this.provinceAndCityName = this.provinceName
        this.handleItem.value1.province = this.provinceName

        // 请求城市
        this.params_citys.parent_id = pid
        this.canClick = false
        this.getProvinceCity()
      } else if (this.cityLevel === 2) {
        // 市
        this.paramRegister.city_id = pid
        this.handleItem.value1.city_id = pid
        this.paramRegister.county_id = ''
        this.cityName = cname
        this.countyName = ''
        this.provinceAndCityName = this.provinceName + '  ' + cname
        this.handleItem.value1.city = this.provinceName + '  ' + cname
        // 请求第三级
        this.params_citys.parent_id = pid
        this.canClick = false
        this.getProvinceCity()
      } else {
        // 第三级 选择完成
        this.paramRegister.county_id = pid
        this.handleItem.value1.county_id = pid
        this.countyName = cname
        this.county_id = pid
        this.provinceAndCityName = this.provinceName + '  ' + this.cityName + '  ' + cname
        this.handleItem.value1.county = this.provinceName + '  ' + this.cityName + '  ' + cname
        this.isShowCityPop = false
        this.isShowYinYing = false
        this.cityLevel = 1
      }
    },
    /* 选择完毕*/
    /* 显示省市列表*/
    backProvince() {
      this.cityLevel--
    },
    // 微信绑定信息
    getLoginUserInfo() {
      getLoginUserInfo().then(res => {
        if (res.errNo == 0) {
          const result = res.result
          this.bindInfoObj.wx_nickname = result.wx_nickname
          this.bindInfoObj.is_bind_weixin = result.is_bind_weixin
          this.bindInfoObj.change_bind_able = result.change_bind_able
          this.is_work_account = result.is_work_account
        }
      })
    },
    // 换绑微信
    toLoginBing() {
      this.$router.push({
        path: '/wxBindLogin'
      })
    },
    // 解除绑定
    removeBindData() {
      const info = {
        agency_id: 2,
        uid: Cookies.get('uid'),
        type: 1
      }
      removeBindData(info).then(res => {
        console.log(res, 'res')
      })
    },
    // 换绑记录
    removeBindData2() {
      const info = {
        agency_id: 2,
        uid: Cookies.get('uid'),
        type: 2
      }
      removeBindData(info).then(res => {
        console.log(res, 'res')
      })
    }

  }

}
</script>

<style lang='scss' scoped>
.style_flex {
  display: flex;
  justify-content: start;
  align-items: center;
}

.style_flex_space_between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.div_page_content{
  .div_item{
    margin-bottom: 25px;
    .div_left{
      justify-content: end;
      width:156px;
      flex-shrink: 0;
      margin-right: 24px;
      .p_xh {
        display: inline-block;
        color: #ee2e2e;
      }
      span {
        line-height: 46px;
        color: #999999;
      }
    }
    .div_right{
      .input {
        width: 302px;
        height: 46px;
        background-color: #ffffff;
        border: solid 1px #cfcfcf;
        padding-left: 25px;
        outline: none;
        font-size: 14px;
      }
      .addNew{
        text-align:left;
        margin-right:230px;
        span{
          color:#ee2e2e !important;
        }
      }
      .select_calss1 {
        width: 302px;
        /deep/ .el-input__inner {
          padding-left: 30px !important;
        }
      }
      .radio {
        display: inline-block;
        width: 146px;
        height: 46px;
        line-height: 46px;
        text-align: center;
        margin-right: 10px;
      }
      .span_tel {
        display: inline-block;
        min-width: 120px;
        font-size: 18px;
        line-height: 46px;
        margin-right: 24px;
      }
      span {
        line-height: 46px;
        color: #999999;
      }
      .div_icon {
        width: 200px;
        height: 64px;
        cursor: pointer;
        .iv_avatar{
          width: 64px;
          height: 64px;
          border-radius: 50%;
        }
        .head{
          display: flex;
          align-items: center;
        }
      }
      .div_icon:hover .div_edi_user_icon {
        display: inline-block;
      }
      .btn_update_tel:hover {
        background-color: #ee2e2e;
        color: white;
      }

      .btn_update_tel {
        width: 84px;
        height: 26px;
        border-radius: 13px;
        background-color: white;
        color: #ee2e2e;
        border: solid 1px #ef2d2e;
        cursor: pointer;
      }
    }
  }

}

.div_reigsit {
  margin: 0 auto;
  position: relative;
  width: 965px;
  // height: 700px;
  background-color: #fff;
  padding: 0 20px 20px 20px;
}
.textarea {
  width: 302px;
  outline: none;
  font-size: 14px;
  padding-top: 10px;
}
.explain{
  width: 400px !important;
  float: left !important;
  text-align: left !important;
}

.checked {
  background-color: #ffeee8;
  border: solid 1px #ee2e2e;
}

.noChecked {
  background-color: #ffffff;
  border: solid 1px #cfcfcf;
}

.dialogCommon {
  position: absolute;
  background: #ffffff;
  padding-bottom: 20px;
  width: 401px;
  z-index: 1;
  box-shadow: 2px 2px 32px 0px rgba(193, 193, 193, 0.72);
}

.chooseCommon {
  position: absolute;
  width: 401px;
  background: #ffffff;
  z-index: 1;
  border: 2px solid #ccc;
  margin-top: -6px;
  margin-left: 8px;
  box-shadow: 2px 2px 32px 0px rgba(193, 193, 193, 0.72);
}

.chooseMajor {
  top: 42.5%;
  margin-top: -8px;
  left: 20%;
}

/deep/ .el-dialog {
  width: 40%;
  top: 23%;
}

/deep/ .el-dialog__wrapper {
}

.chooseCity {
  top: 47%;
  left: 20%;
  margin-top: -8px;
  padding-bottom: 20px;
}

.triangle_border_up {
  width: 0;
  height: 0;
  border-width: 0 10px 10px;
  border-style: solid;
  border-color: transparent transparent #333; /*透明 透明  灰*/
  margin: 40px auto;
  position: relative;
}

.dialogCommonP {
  height: 36px;
  line-height: 36px;
  text-align: left;
  color: #444;
  font-size: 16px;
}

.dialogCommonImg {
  position: absolute;
  top: 0;
  left: 100%;
  margin-left: -32px;
  width: 32px;
  height: 32px;
}

.chooseCityLi {
  margin-top: 20px;
  float: left;
}

.chooseP {
  padding-left: 12px;
  background: none repeat scroll 0 0 #f7f7f7;
}

.chooseSpan {
  font-size: 15px;
  color: #444444;
}

.chooseA {
  color: #ee2e2e;
  font-size: 15px;
  cursor: pointer;
}

.chooseDiv {
  margin: 0;
  .chooseDiv ul {
    padding: 0px 12px 0px 12px;
  }

  .chooseDivLi {
    margin-right: 12px;
    text-align: center;
    height: 18px;
  }

  .chooseDivA {
    text-align: center;
    font-size: 13px;
    cursor: pointer;
    color: #444444;
  }

  .chooseDivA:hover {
    color: #ee2e2e;
  }
}

.view_yiying {
  position: absolute;
  top: 0;
  left: -235px;
  width: 1200px;
  height: 1200px;
  margin: 0;
  background-color: rgba(193, 193, 193, 0);
}

.btn_save_info {
  width: 90px;
  height: 28px;
  border-radius: 6px;
  cursor: pointer;
  background-color: #ee2e2e;
  color: white;
  border: 1px solid #ee2e2e;
  margin: 48px 0px 0px -470px;
}

.zhul_diq_jiao {
  background: url(../../../assets/image/zhul_lyBg.gif) repeat scroll -321px 0 rgba(0, 0, 0, 0);
  clear: both;
  height: 9px;
  left: 30px;
  overflow: hidden;
  position: absolute;
  top: -9px;
  width: 11px;
  z-index: 9999;
}

.dialogBindPhone {
  height: 310px;
  top: 28%;
  left: 20%;
  position: absolute;
  padding: 20px 25px;
  width: 460px;
}

.dialogBindPhoneTitle {
  font-weight: bold;
  font-size: 14px;
}

.dialogBindPhone li {
  height: 46px;
  margin-top: 8px;
}

.dialogBindPhone li:first-child {
  margin-top: 10px;
}

.dialogBindPhone li span {
  font-size: 12px;
  line-height: 46px;
  color: #999999;
}

.dialogBindCheckedCode {
  margin-top: 8px;
  //position: absolute;
  display: inline-block;
  width: 302px;
  height: 46px;
  margin-left: 11px;
  background-color: #ffffff;
  border: solid 1px #cfcfcf;
  padding-left: 25px;
  outline: none;
}

::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #999;
  font-size: 12px;
}

::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #999;
  font-size: 12px;
}

:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: #999;
  font-size: 12px;
}

.dialogBindCheckedCode input {
  float: left;
  border: none;
  outline: none;
  line-height: 44px;
  margin-right: -50px;
}

.btn_bind_Phone {
  width: 100px;
  height: 28px;
  border-radius: 6px;
  cursor: pointer;
  background-color: #ee2e2e;
  color: white;
  border: 1px solid #ee2e2e;
  margin-top: 25px;
}

.line {
  display: inline-block;
  height: 26px;
  width: 1px;
  margin-top: 10px;
  background-color: #999;
}

.getCode {
  display: inline-block;
  margin-left: 8px;
  position: absolute;
  line-height: 44px;
  background: white;
  font-size: 12px;
  color: #999;
  cursor: pointer;
}

.getCode:hover {
  color: #ee2e2e;
}

.chooseMajorLi {
  padding: 14px 0px;
  border-bottom: 1px solid #f3f3f3;
}

.chooseMajorLi:first-child {
  margin-top: 8px;
}

.chooseMajorLi:last-child {
  border: none;
}

.chooseMajorDl {
  float: left;
  text-align: left;
  margin-top: 6px;
}

.chooseMajorLi p {
  width: 200px;
  font-size: 14px;
}

.chooseMajorLi dl a {
  font-size: 12px;
}

.div_edi_user_icon {
  display: inline-block;
  position: absolute;
  top: 0px;
  bottom: 0px;
  margin: auto;
}

.div_icon {
  position: relative;
  left: 0px;
  display: inline-block;
  width: 200px;
  height: 64px;
  cursor: pointer;
}

.div_edi_user_icon {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 64px;
  width: 64px;
  line-height: 64px;
  margin: auto;
  display: none;
  text-align: center;
  color: #fff;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 51%;
  cursor: pointer;
}

.div_icon:hover .div_edi_user_icon {
  display: inline-block;
}

.iv_icon {
  float: left;
  height: 64px;
  width: 64px;
}

.el_select {
  width: 302px;
  height: 46px;
  border-radius: 0px;
}

//修改单个的选项的样式
.item {
  color: #595959;
}

::v-deep {
  .el-select .el-input.is-focus .el-input__inner {
    border-color: #cfcfcf;
  }

  .el-select .el-input__inner:focus {
    border-color: #cfcfcf;
  }

  .el-input__inner {
    height: 46px;
    border: solid 1px #cfcfcf;
    border-radius: 0px;
    padding-left: 25px;
    font-size: 14px;

    &::placeholder {
      color: #999999;
      font-size: 10px;
    }
  }

  .email_tip {
    display: inline-block;
    margin-left: 20px;
    max-width: 400px;
    vertical-align: middle;
    color: red;
    font-size: 14px;
  }

  .avatar-uploader .el-upload {
    border: none;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .p_xh {
    display: inline-block;
    color: #ee2e2e;
  }
}
.select_calss {
  option {
    text-align: left !important;
  }
}

.dialogBindPhone_wx{
  height: 205px;
}
/deep/.select, .selected {
  width: 100% !important;
  height: 34px !important;
  color: #ee2d2e !important;
}
</style>
